import { MouseEvent } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';

import { DataItem } from '@/api/posts/interfaces';
import { useAdMemeObserver } from '@/hooks/useAdMemeObserver';
import { Wrapper } from '@/components/atoms/Wrapper';

import { Cta } from '@/components/atoms/Cta';
import { ImageContainer } from '../ImageContainer';
import { SurveySection } from '../SurveySection';
import { LinksSection } from '../LinksSection';
import { TagCategorySection } from '../TagCategorySection';
import { UserSection } from '../UserSection';
import { PostContentSection } from '../PostContentSection';

interface ISimplePostProps {
  data: DataItem;
  isFeedPost?: boolean;
  postCardId: string;
}

export const SimplePost = ({ data, postCardId, isFeedPost = false }: ISimplePostProps) => {
  const router = useRouter();
  const { pathname } = router;
  const isSinglePostPathname = pathname === '/post/[...slug]' && !isFeedPost;
  const {
    postCoverPicture,
    contentIsQuote,
    additionalText,
    pollQuestion,
    mediaSource,
    quoteAuthor,
    publishedAt,
    categories,
    postVideo,
    isAdPost,
    content,
    source,
    adUrls,
    author,
    title,
    slug,
    type,
    tags,
    ctaTitle,
    ctaLabel,
    ctaLink,
  } = data.attributes;
  const userAvatar = author?.data?.attributes?.avatar?.data?.attributes?.formats?.medium?.url;
  const isPictureTypeGiF = postCoverPicture?.data?.attributes?.ext === '.gif';
  const picture = isPictureTypeGiF
    ? postCoverPicture?.data?.attributes?.url
    : postCoverPicture?.data?.attributes?.formats?.medium?.url;
  const userName = author?.data?.attributes?.username;
  const video = postVideo?.data?.attributes.url;
  const postUrl = `post/${data?.id}/${title}`;
  const postType = type?.data?.[0]?.id;
  const userId = author?.data?.id;
  const cta = {
    title: ctaTitle || '',
    label: ctaLabel || '',
    link: ctaLink || '',
  };
  const isCTA = cta.title && cta.label && cta.link;

  const onVideoClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  useAdMemeObserver({ slug, postId: data?.id, postCardId, adUrls, isAdPost });

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {postType === 2 && (
        <>
          <Wrapper className="gap-3 px-3 regular:px-6 mb-6" fullWidth>
            <UserSection id={userId} name={userName} date={publishedAt} avatar={userAvatar} />
            <h2
              className={clsx(
                'text-2xl font-semibold hover:opacity-90 cursor-pointer break-words',
                isSinglePostPathname && 'hover:!opacity-100 !cursor-default',
              )}
              itemProp="headline"
            >
              {title}
            </h2>
            <TagCategorySection
              tags={tags}
              postId={data?.id}
              postTitle={title}
              postUrl={postUrl}
              categories={categories}
              isSinglePost={isSinglePostPathname}
            />
            {isSinglePostPathname && <LinksSection source={source} />}
          </Wrapper>

          {video && (
            <div
              className={clsx(
                'max-h-[100vw] sm:max-h-500 overflow-hidden relative mx-3 regular:mx-6',
                content ? 'rounded-t-xl' : 'rounded-xl',
              )}
            >
              <video controls className="w-full min-h-full" poster={picture} onClick={onVideoClick}>
                <track kind="captions" />
                <source src={video} type="video/mp4" />
              </video>
            </div>
          )}

          {picture && !video && (
            <ImageContainer
              isSinglePostPathname={isSinglePostPathname}
              mediaSource={mediaSource}
              imageSource={picture}
              content={content}
              alt={title}
            />
          )}
          {isCTA && <Cta cta={cta} />}
          {content && (
            <PostContentSection contentIsQuote={contentIsQuote} quoteAuthor={quoteAuthor} content={content} />
          )}
          <p
            className={clsx(
              'text-gray-300 font-light break-words',
              additionalText ? 'mt-6 mb-4 px-3 regular:px-6' : 'my-3',
            )}
          >
            {additionalText}
          </p>
          {!!pollQuestion?.data && <SurveySection pollQuestion={pollQuestion} postId={data?.id} />}
        </>
      )}
    </>
  );
};
