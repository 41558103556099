import { ArrowRightBlack } from '@/components/icons/ArrowRightBlack';

interface ICtaProps {
  cta: {
    title: string;
    label: string;
    link: string;
  };
}

export const Cta = ({ cta }: ICtaProps) => {
  const { title, label, link } = cta;
  return (
    <div className="flex justify-between items-center text-m font-semibold border border-primary-600 rounded-xl p-4 md:px-6 mx-3 my-3 regular:mx-6">
      <span>{title}</span>
      <a
        href={link}
        className="bg-primary-600 py-2.5 focus:ring-2 focus:ring-primary-300 focus:outline-none hover:bg-primary-700 focus:bg-primary-700 text-black text-s font-semibold rounded-full regular:rounded-md disabled:cursor-not-allowed gap-2 px-2.5 regular:px-5 flex items-center min-w-fit h-11"
      >
        {label}
        {ArrowRightBlack}
      </a>
    </div>
  );
};
