import { Fragment, useState } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import ReactGA from 'react-ga4';

import { DataItem } from '@/api/posts/interfaces';
import { useAdPageName } from '@/hooks/useAdPageName';
import { getSinglePostPath } from '@/helpers/getSinglePostPath';
import { navigateToNewWindow } from '@/helpers/navigateToNewWindow';
import { Card } from '@/components/atoms/Card';
import { Wrapper } from '@/components/atoms/Wrapper';
import { LikesContainer } from '@/components/molecules/LikesContainer';
import { EmbedPost } from '@/components/molecules/EmbedPost';
import { SocialsContainer } from '@/components/molecules/SocialsContainer';
import { SimplePost } from '@/components/molecules/SimplePost';
import { BlogPost } from '@/components/molecules/BlogPost';
import { GalleryPost } from '@/components/molecules/GalleryPost';
import { AdPlaceholder } from '@/components/molecules/AdPlaceholder';
import { FeedAds, SmartAds } from '@/components/organisms/AdCompanion';
import { getRemoteConfigValue } from '@firebaseDir/remoteConfig';
import { Separator } from '@/components/atoms/Separator';
import { useUpdateInterstitialTriggers } from '@/components/organisms/AdCompanion/hooks/useUpdateInterstitialTriggers';
import { getGAPostType } from '@/helpers/getGAPostType';

export const DynamicPopularComment = dynamic(
  () => import('@/components/molecules/PopularComment').then((mod) => mod.PopularComment),
  {
    ssr: false,
  },
);

interface IPostsFeedTemplate {
  posts: DataItem[];
}

export const PostsFeedTemplate = ({ posts }: IPostsFeedTemplate) => {
  const router = useRouter();
  const { getAdBannerName } = useAdPageName();
  const { updateInterstitialActionCount } = useUpdateInterstitialTriggers();
  const [isLocalLikesCount, setIsLocalLikesCount] = useState<boolean>(false);
  const pageNameInFeed = getAdBannerName('in_feed');

  const handleLocalLikesCount = () => {
    setIsLocalLikesCount(true);
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {posts?.map((post, index) => {
        const { type, likes, dislikes, slug, publishedAt, title } = post.attributes;
        const postType = type?.data?.[0]?.id;
        const postPath = getSinglePostPath(post.id, slug as string, postType);
        const postGAType = getGAPostType(postType);

        const handleRoute = () => {
          ReactGA.event('post_full_display', {
            entry_point: router.pathname,
            type: postGAType,
            post_id: post.id,
            post_title: title,
          });
          updateInterstitialActionCount();
          window.location.href = postPath;
        };

        return (
          <Fragment key={post.id}>
            <Card
              onClick={handleRoute}
              onAuxClick={() => navigateToNewWindow(postPath)}
              className="!w-full hover:ease-in hover:duration-150 hover:border-gray-600 cursor-pointer !px-0 relative"
              id={`feedComponent_${index}`}
              itemScope
              itemType="https://schema.org/Article"
            >
              <meta itemProp="datePublished" content={publishedAt} />
              {post.attributes.userBadgeIcon.data && (
                <img
                  src={post.attributes.userBadgeIcon.data.attributes.url}
                  alt=""
                  className="absolute top-0 right-6"
                />
              )}
              <EmbedPost data={post} isFeedPost />
              <SimplePost data={post} postCardId={`feedComponent_${index}`} isFeedPost />
              <BlogPost data={post} isFeedPost />
              <GalleryPost data={post} isFeedPost />
              <Wrapper className="flex-col justify-between xxs:flex-row px-3 regular:px-6" fullWidth>
                <LikesContainer
                  postId={post.id}
                  postTitle={title}
                  likesCount={likes}
                  dislikesCount={dislikes}
                  isLocalLikesCount={isLocalLikesCount}
                  handleLocalLikesCount={handleLocalLikesCount}
                />
                <SocialsContainer
                  id={post.id}
                  slug={slug}
                  socialTitle={post.attributes.socialTitle}
                  isMainPage={post.attributes.isMainPage}
                  commentsCount={post.attributes.commentsCount}
                />
              </Wrapper>
              <DynamicPopularComment data={post} />
            </Card>
            <Separator />
            <FeedAds
              index={index}
              adSeparator={<Separator />}
              pageName={pageNameInFeed}
              getRemoteConfigValue={getRemoteConfigValue}
              adPlaceholder={<AdPlaceholder variant="inFeed" />}
            />
            {index === 0 && (
              <SmartAds
                variant="outstreamVideo"
                adSeparator={<Separator />}
                adPlaceholder={<AdPlaceholder variant="outstreamVideo" />}
                id={index}
              />
            )}
          </Fragment>
        );
      })}
    </>
  );
};
